<template>
  <div>
    <a-modal v-model="modalVisible" :title="modalTitle" :width="1250" :centered="true" :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button @click="modalVisible=false">{{showType=='detail' ? '关闭' : '取消'}}</a-button>
        <a-button v-if="showType=='add' || showType=='edit'|| showType=='paste'" type="primary" @click="confirm()">提交</a-button>
      </template>
      <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns" :model="formData" :rules="formRules" :label-col="{span:6}" :wrapper-col="{span:16}">
        <a-form-model-item label="项目名称" prop="monitorpointnum">
          <a-select :disabled="showType=='detail'" v-model="formData.monitorpointnum" placeholder="请选择" show-search :filter-option="filterOption">
            <a-select-option v-for="(item, index) in monitorpointList" :key="index" :value="item.monitorpointnum">{{item.monitorpointname}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="图位号" prop="num">
          <a-input :disabled="showType=='detail'" v-model="formData.num"></a-input>
          <span style="color: red">提示：“图位号”指绿化区域平面图中的区域编号，例如“19”</span>
        </a-form-model-item>
        <a-form-model-item label="植物名称" prop="plant_name">
          <a-input :disabled="showType=='detail'" v-model="formData.plant_name"></a-input>
        </a-form-model-item>
        <a-form-model-item label="科属" prop="genus">
          <a-input :disabled="showType=='detail'" v-model="formData.genus"></a-input>
        </a-form-model-item>
        <a-form-model-item label="花色" prop="decor">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.decor" />
        </a-form-model-item>
        <a-form-model-item label="花期" prop="florescence">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.florescence" />
        </a-form-model-item>
        <a-form-model-item label="位置" prop="position">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.position" />
        </a-form-model-item>
        <a-form-model-item label="单位" prop="units">
          <a-select :disabled="showType=='detail'" v-model.trim="formData.units">
            <a-select-option value="株">株</a-select-option>
            <a-select-option value="平方米">平方米</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="数量" prop="quantity">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.quantity" />
        </a-form-model-item>
        <a-form-model-item label="区域" prop="region_name">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.region_name" />
        </a-form-model-item>
        <a-form-model-item label="绿化区域平面图名称" prop="region">
          <a-select :disabled="showType=='detail'" v-model="formData.region" placeholder="请选择" show-search :filter-option="filterOption" @change="regionChange">
            <a-select-option v-for="(item, index) in afforestAreaPicList" :key="index" :value="item.afforestareapicurl">{{item.afforestareapicname}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="绿化区域平面图" prop="region_url">
          <a-upload
              :disabled="true"
              name="fileList"
              :headers="uploadHeaders"
              :data="uploadBusinessManagement"
              list-type="picture-card"
              action="/upload"
              :file-list="regionList"
              @preview="handlePreview(formData.region_url)"
              @change="regionUpload">
<!--            <a-button v-if="showType!='detail'"><a-icon type="upload" /> 点击上传文件 </a-button>-->
          </a-upload>
        </a-form-model-item>
        <a-form-model-item label="生长习性" prop="growth_habits">
          <textarea :disabled="showType=='detail'" v-model.trim="formData.growth_habits" style="width: 390px"/>
        </a-form-model-item>
        <a-form-model-item label="植物图片" prop="pic_url">
          <a-upload
              :disabled="showType==='detail'"
              name="fileList"
              :headers="uploadHeaders"
              :data="uploadBusinessManagement"
              list-type="picture-card"
              action="/upload"
              :file-list="picList"
              @preview="handlePreview(formData.pic_url)"
              @change="picUpload">
            <a-button v-if="showType!='detail'"><a-icon type="upload" /> 点击上传图片 </a-button>
          </a-upload>
          <h5 style="color: red">注意：上传的图片格式应为jpg/png格式</h5>
        </a-form-model-item>
        <a-form-model-item label="备注" prop="remark">
          <textarea :disabled="showType=='detail'" v-model.trim="formData.remark" style="width: 390px"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel" width="1080px">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>
<script>
import moment from 'moment'
import {mapGetters, mapState} from 'vuex'
import areaselect from '@/mixins/areaselect'
import deptselect from '@/mixins/deptselect'
import {getMonitorPointNameListByCondition} from "A/monitoring";
import {addLedgerAfforest, getLedgerAfforestByCondition, modifyLedgerAfforest} from "A/businessmanagement";
import {getAfforestAreaPicListByCondition} from "A/patrol";
export default {
  mixins: [areaselect, deptselect],
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    detailData: {
      default: null
    }
  },
  data() {
    return {
      moment,
      modalBodyStyle: {
        height: '660px',
        overflowY: 'auto',
      },
      modalVisible: false,
      formData: {
        monitorpointnum: '',
        num:"",
        plant_name:"",
        genus:"",
        decor:"",
        florescence:"",
        growth_habits:"",
        position:"",
        units:"",
        quantity:"",
        remark:"",
        pic_url:"",
        region_name:"",
        region:"",
        region_url:"",
        create_time:"",
        creator:"",
      },
      //这里面的数据属于必填项
      formRules: {
        monitorpointnum: [{required: true, message: '请选择项目'}],
        num: [{required: true, message: '请输入图位号'}],
        plant_name: [{required: true, message: '请输入植物名称'}],
        position: [{required: true, message: '请输入位置'}],
        units: [{required: true, message: '请输入单位'}],
        quantity: [{required: true, message: '请输入数量'}],
        // region_name: [{required: true, message: '请填写区域'}],
        region: [{required: true, message: '请选择绿化区域平面图'}],
      },
      monitorpointList:[],
      afforestAreaPicList:[],
      baseurl:'https://fda.u-lake.com/',
      picList: [],
      regionList: [],
      previewImage: '',
      previewVisible:false,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['uploadHeaders', 'uploadBusinessManagement']),
    modalTitle() {
      if(this.showType == 'add') {
        return '新增'
      }else if(this.showType == 'edit'||this.showType == 'paste') {
        return '修改'
      }else if(this.showType == 'detail') {
        return '详情'
      }else {
        return '';
      }
    },
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.resetData();
        this.getMonitorPointNameList();
        this.getAfforestAreaPic();
        this.initDetail();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetData();
        this.$emit('get-operation-result', 'success', '操作成功');
        this.$emit('update:visible', false);
      }
    },
  },
  created() {
    this.modalVisible = this.visible;
  },
  methods: {
    getMonitorPointNameList(){
      getMonitorPointNameListByCondition({userdepsid:this.userInfo.userdepid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.monitorpointList=res.item
        }
      })
    },
    getAfforestAreaPic(){
      getAfforestAreaPicListByCondition({userdepsid:this.userInfo.userdepid}).then(res => {
        if(res && res.returncode == '0') {
          this.afforestAreaPicList=res.item
        }
      })
    },
    regionChange(e){
      this.formData.region_url=e
      if (this.formData.region_url) {
        this.previewImage=this.baseurl+this.formData.region_url
        let nameIndex = this.formData.region_url.lastIndexOf('/');
        if (nameIndex < 0) {
          nameIndex = this.formData.region_url.lastIndexOf('\\');
        }
        let fileName = this.formData.region_url.substr(nameIndex + 1);
        this.regionList = [{
          uid: 'regionid',
          name: fileName,
          status: 'done',
          url: this.formData.region_url,
        }];
      }
    },
    resetData() {
      if (this.$refs.modalForm) {
        this.$refs.modalForm.resetFields();
      }
    },
    initDetail() {
      if (this.detailData && this.detailData.ledger_id) {
        if(this.showType == 'edit' || this.showType == 'detail' || this.showType === 'paste') {
          this.$nextTick(() => {
            let params = {
              ledger_id: this.detailData.ledger_id
            }
            getLedgerAfforestByCondition(params).then(res => {
              if (res && res.returncode == '0') {
                this.formData = res.item[0];
                if (this.formData.pic_url) {
                  this.previewImage=this.baseurl+this.formData.pic_url
                  let nameIndex = this.formData.pic_url.lastIndexOf('/');
                  if (nameIndex < 0) {
                    nameIndex = this.formData.pic_url.lastIndexOf('\\');
                  }
                  let fileName = this.formData.pic_url.substr(nameIndex + 1);
                  this.picList = [{
                    uid: 'pic_urlid',
                    name: fileName,
                    status: 'done',
                    url: this.formData.pic_url,
                  }];
                }
                if (this.formData.region_url) {
                  this.previewImage=this.baseurl+this.formData.region_url
                  let nameIndex = this.formData.region_url.lastIndexOf('/');
                  if (nameIndex < 0) {
                    nameIndex = this.formData.region_url.lastIndexOf('\\');
                  }
                  let fileName = this.formData.region_url.substr(nameIndex + 1);
                  this.regionList = [{
                    uid: 'regionid',
                    name: fileName,
                    status: 'done',
                    url: this.formData.region_url,
                  }];
                }
              }
            })
          })
        }
      }
    },
    confirm() {
      if (this.showType == 'add' || this.showType == 'edit' || this.showType === 'paste') {
        this.$refs.modalForm.validate(valid => {
          if (valid) {
            this.formData.create_time=moment(new Date()).format("YYYYMMDDHHmmss")
            this.formData.creator=this.userInfo.username
            let params = {
              ...this.formData,
            };
            if (this.showType == 'add' || this.showType === 'paste') {
              this.showLoading();
              addLedgerAfforest(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            } else if(this.showType == 'edit'){
              this.showLoading();
              modifyLedgerAfforest(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            }else{
              this.$message.warning('请确认信息填写完整')
            }
          } else {
            this.$message.warning('请确认信息填写完整')
            return false;
          }
        })
      } else {
        this.modalVisible = false;
      }
    },
    picUpload(info) {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      fileList = fileList.map(file => {
        if (file.response) {
          file.url = file.response.urlPath;
          this.formData.pic_url = file.response.urlPath;
        }
        return file;
      });
      this.picList = fileList;
    },
    regionUpload(info) {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      fileList = fileList.map(file => {
        if (file.response) {
          file.url = file.response.urlPath;
          this.formData.region_url = file.response.urlPath;
        }
        return file;
      });
      this.regionList = fileList;
    },
    async handlePreview(url) {
      this.previewImage = this.baseurl + url;
      this.previewVisible = true;
    },
    handleCancel() {
      this.previewVisible = false;
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
  }
}
</script>
<style lang="scss" scoped>
.select-drop-down-platform {
  width: 240px;
}
</style>